// import React, { useState } from 'react'
// import AdminSidebar from './AdminSidebar'
// import iziToast from "izitoast";
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../AuthContext';
// import validator from "validator";

// const  PrintManagementAdd = () => {

//     const [showPassword, setShowPassword] = useState(null);
//     const [newPassword, setNewPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [errors, setErrors] = useState({});
//     const navigate = useNavigate();
//     const { apipath } = useAuth();

//     const [formData, setFormData] = useState({
//         name: '',
//         lastName: '',
//         phone: '',
//         email: '',
//         city: '',
//         state: '',
//         country: '',
//         address: '',
//         password: '',
//         role: 'subadmin',
//         agreeToTerms: false,
//     });

//     const handleNewPasswordChange = (event) => {
//         setNewPassword(event.target.value);
//         setFormData((prevFormData) => ({
//             ...prevFormData,
//             password: event.target.value,
//         }));
//     };

//     const handleConfirmPasswordChange = (event) => {
//         setConfirmPassword(event.target.value);
//     };

//     const handleVisibility = (index) => {
//         setShowPassword(showPassword === index ? null : index);
//     };

//     const handleChange = (e) => {
//         const { name, value, type, checked } = e.target;
//         setFormData((prevFormData) => ({
//             ...prevFormData,
//             [name]: type === 'checkbox' ? checked : value,
//         }));

//         // Clear validation error when user starts typing
//         if (errors[name]) {
//             setErrors((prevErrors) => ({
//                 ...prevErrors,
//                 [name]: '',
//             }));
//         }
//     };

//     const handleRegister = async (e) => {
//         e.preventDefault();

//         // Perform validation
//         const validationErrors = {};

//         if (!formData.name) {
//             validationErrors.name = 'First Name is required';
//         }
//         if (!formData.lastName) {
//             validationErrors.lastName = 'Last Name is required';
//         }
//         if (!formData.email) {
//             validationErrors.email = 'Email is required';
//         } else if (!validator.isEmail(formData.email)) {
//             validationErrors.email = 'Invalid email address';
//         }
//         if (!formData.phone) {
//             validationErrors.phone = 'Phone number is required';
//         }
//         if (!formData.password) {
//             validationErrors.password = 'Password is required';
//         } else if (formData.password.length < 8) {
//             validationErrors.password = 'Password must be at least 8 characters';
//         }
//         if (newPassword !== confirmPassword) {
//             validationErrors.confirmPassword = 'Passwords do not match';
//         }
//         if (!formData.agreeToTerms) {
//             validationErrors.agreeToTerms = 'You must agree to the terms and privacy policies';
//         }

//         // Set validation errors
//         setErrors(validationErrors);

//         if (Object.keys(validationErrors).length > 0) {
//             return; // Stop form submission if there are validation errors
//         }

//         try {
//             const response = await fetch(apipath + '/users/register', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(formData),
//             });

//             const data = await response.json();

//             if (response.status === 201) {
//                 const savedUserId = data.userId;
//                 const emailId = data.email;

//                 iziToast.success({
//                     title: 'Success',
//                     message: data.message || 'Registration successful',
//                     position: 'topCenter',
//                     timeout: 2000,
//                 });
//                 navigate('/print-management');
//             } else if (response.status === 400) {
//                 iziToast.error({
//                     title: 'Error',
//                     message: data.message || 'Email or Mobile No. already exists. Please try another email or mobile no.',
//                     position: 'topCenter',
//                 });
//             } else {
//                 iziToast.error({
//                     title: 'Error',
//                     message: 'Registration failed. Please try again later.',
//                     position: 'topCenter',
//                 });
//             }
//         } catch (error) {
//             iziToast.error({
//                 title: 'Error',
//                 message: 'Registration failed. Please try again later.',
//                 position: 'topCenter',
//             });
//         }
//     };


//     return (
//         <>
//             <AdminSidebar />
//             <div className="home">
//                 <div class="toggle-sidebar" style={{ display: "none" }}>
//                     <i class="bi bi-menu"></i>
//                 </div>
//                 <div className="dashboard">
//                     <div className="dashboard-header">
//                         <h1> Add Sub Admin</h1>
//                     </div>
//                     <div className="container-fluid mt-3">
//                         <form className="row login-form" onSubmit={handleRegister}>
//                         <input type="text" name="role" value={formData.role} onChange={handleChange} hidden/>
//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="name" className='mb-2'>First Name</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Enter name"
//                                     name="name"
//                                     value={formData.name}
//                                     onChange={handleChange}
//                                     maxLength="100"
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="name" className='mb-2'>Last Name</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Enter lastName"
//                                     name="lastName"
//                                     value={formData.lastName}
//                                     onChange={handleChange}
//                                     maxLength="100"
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="phone" className='mb-2'> Phone</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Enter phone"
//                                     name="phone"
//                                     value={formData.phone}
//                                     onChange={handleChange}
//                                     maxLength="100"
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="email" className='mb-2'>Email</label>
//                                 <input
//                                     type="email"
//                                     className="form-control"
//                                     placeholder="Enter email"
//                                     name="email"
//                                     value={formData.email}
//                                     onChange={handleChange}
//                                     maxLength="100"
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="city" className='mb-2'>City</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Enter city"
//                                     name="city"
//                                     value={formData.city}
//                                     onChange={handleChange}
//                                     maxLength="100"
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="state" className='mb-2'>State</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Enter state"
//                                     name="state"
//                                     value={formData.state}
//                                     onChange={handleChange}
//                                     maxLength="100"
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="country" className='mb-2'>Country</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Enter country"
//                                     name="country"
//                                     value={formData.country}
//                                     onChange={handleChange}
//                                     maxLength="100"
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="address" className='mb-2'>Address</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Enter address"
//                                     name="address"
//                                     value={formData.address}
//                                     onChange={handleChange}
//                                     maxLength="100"
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="name" className='mb-2'>New Password</label>
//                                 <input
//                                         type={showPassword === 1 ? "text" : "password"}
//                                         className={`form-control ${errors.password && "is-invalid"}`}
//                                         placeholder="Enter password"
//                                         aria-label="password"
//                                         aria-describedby="basic-addon2"
//                                         name="password"
//                                         id="newPassword"
//                                         value={newPassword}
//                                         onChange={handleNewPasswordChange}
//                                     />
//                                     <div className="input-group-append login-append">
//                                         <span className="input-group-text" id="basic-addon2">
//                                             <i className={showPassword === 1 ? "bi bi-eye-slash" : "bi bi-eye"} onClick={() => handleVisibility(1)}></i>
//                                         </span>
//                                     </div>
//                             </div>
//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="name" className='mb-2'>Confirm Password</label>
//                                 <input
//                                         type={showPassword === 2 ? "text" : "password"}
//                                         className={`form-control ${errors.confirmPassword && "is-invalid"}`}
//                                         aria-label="password"
//                                         aria-describedby="basic-addon2"
//                                         placeholder="Enter your confirm password"
//                                         id="confirmPassword"
//                                         value={confirmPassword}
//                                         onChange={handleConfirmPasswordChange}
//                                     />
//                                     <div className="input-group-append login-append">
//                                         <span className="input-group-text" id="basic-addon2">
//                                             <i className={showPassword === 2 ? "bi bi-eye-slash" : "bi bi-eye"} onClick={() => handleVisibility(2)}></i>
//                                         </span>
//                                     </div>
//                             </div>
//                             <div className="login-button col-lg-8 mx-auto">
//                                 <button >Add Sub Admin</button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }   

// export default PrintManagementAdd


import React, { useState } from 'react';
import AdminSidebar from './AdminSidebar';
import iziToast from "izitoast";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import validator from "validator";

const PrintManagementAdd = () => {
    const [showPassword, setShowPassword] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { apipath } = useAuth();

    const [formData, setFormData] = useState({
        name: '',
        lastName: '',
        phone: '',
        email: '',
        city: '',
        state: '',
        country: '',
        address: '',
        password: '',
        role: 'subadmin',
        agreeToTerms: false,
    });

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            password: event.target.value,
        }));
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleVisibility = (index) => {
        setShowPassword(showPassword === index ? null : index);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));

        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        const validationErrors = {};

        if (!formData.name) {
            validationErrors.name = 'First Name is required';
        }
        if (!formData.lastName) {
            validationErrors.lastName = 'Last Name is required';
        }
        if (!formData.email) {
            validationErrors.email = 'Email is required';
        } else if (!validator.isEmail(formData.email)) {
            validationErrors.email = 'Invalid email address';
        }
        if (!formData.phone) {
            validationErrors.phone = 'Phone number is required';
        }
        if (!formData.password) {
            validationErrors.password = 'Password is required';
        } else if (formData.password.length < 8) {
            validationErrors.password = 'Password must be at least 8 characters';
        }
        if (newPassword !== confirmPassword) {
            validationErrors.confirmPassword = 'Passwords do not match';
        }
        if (!formData.agreeToTerms) {
            validationErrors.agreeToTerms = 'You must agree to the terms and privacy policies';
        }

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            return; 
        }

        try {
            const response = await fetch(apipath + '/users/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (response.status === 201) {
                iziToast.success({
                    title: 'Success',
                    message: data.message || 'Registration successful',
                    position: 'topCenter',
                    timeout: 2000,
                });
                navigate('/print-management');
            } else if (response.status === 400) {
                iziToast.error({
                    title: 'Error',
                    message: data.message || 'Email or Mobile No. already exists. Please try another email or mobile no.',
                    position: 'topCenter',
                });
            } else {
                iziToast.error({
                    title: 'Error',
                    message: 'Registration failed. Please try again later.',
                    position: 'topCenter',
                });
            }
        } catch (error) {
            console.error("Registration error:", error); // Debugging output
            iziToast.error({
                title: 'Error',
                message: 'Registration failed. Please try again later.',
                position: 'topCenter',
            });
        }
    };

    return (
        <>
            <AdminSidebar />
            <div className="home">
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className="bi bi-menu"></i>
                </div>
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Add Sub Admin</h1>
                    </div>
                    <div className="container-fluid mt-3">
                        <form className="row login-form" onSubmit={handleRegister}>
                            <input type="text" name="role" value={formData.role} onChange={handleChange} hidden/>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="name" className='mb-2'>First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    maxLength="100"
                                    required
                                />
                                {errors.name && <div className="text-danger">{errors.name}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="lastName" className='mb-2'>Last Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter last name"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    maxLength="100"
                                    required
                                />
                                {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="phone" className='mb-2'>Phone</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter phone"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    maxLength="10"
                                    autoComplete="off"
                                    onKeyPress={(event) => {
                                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                                {errors.phone && <div className="text-danger">{errors.phone}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="email" className='mb-2'>Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    maxLength="100"
                                    required
                                />
                                {errors.email && <div className="text-danger">{errors.email}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="city" className='mb-2'>City</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter city"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    maxLength="100"
                                    required
                                />
                                {errors.city && <div className="text-danger">{errors.city}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="state" className='mb-2'>State</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter state"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    maxLength="100"
                                    required
                                />
                                {errors.state && <div className="text-danger">{errors.state}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="country" className='mb-2'>Country</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter country"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleChange}
                                    maxLength="100"
                                    required
                                />
                                {errors.country && <div className="text-danger">{errors.country}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="address" className='mb-2'>Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter address"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    maxLength="100"
                                    required
                                />
                                {errors.address && <div className="text-danger">{errors.address}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="password" className='mb-2'>Password</label>
                                <input
                                    type={showPassword === 1 ? "text" : "password"}
                                    className="form-control"
                                    placeholder="Enter password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleNewPasswordChange}
                                    required
                                />
                                <button type="button" onClick={() => handleVisibility(1)}>Toggle Password</button>
                                {errors.password && <div className="text-danger">{errors.password}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="confirmPassword" className='mb-2'>Confirm Password</label>
                                <input
                                    type={showPassword === 2 ? "text" : "password"}
                                    className="form-control"
                                    placeholder="Confirm password"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    required
                                />
                                <button type="button" onClick={() => handleVisibility(2)}>Toggle Confirm Password</button>
                                {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword}</div>}
                            </div>
                            <div className="mb-3 col-lg-12">
                                <input
                                    type="checkbox"
                                    name="agreeToTerms"
                                    checked={formData.agreeToTerms}
                                    onChange={handleChange}
                                />
                                <label htmlFor="agreeToTerms" className='ms-2'>I agree to the terms and privacy policies</label>
                                {errors.agreeToTerms && <div className="text-danger">{errors.agreeToTerms}</div>}
                            </div>
                            <div className="mb-3 col-lg-12">
                                <button type="submit" className="btn btn-primary">Register</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrintManagementAdd;
