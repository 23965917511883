import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import iziToast from "izitoast";
import validator from "validator";
import { useAuth } from "../AuthContext";

const Register = () => {
    const [showPassword, setShowPassword] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { apipath } = useAuth();
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        password: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        country: "",
        dob: "",
        role:'user',
        agreeToTerms: false,
    });

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            password: event.target.value,
        }));
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleVisibility = (index) => {
        setShowPassword(showPassword === index ? null : index);
    };

const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
    }));

    // Clear validation error when user starts typing
    if (errors[name]) {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    }
};

const handleRegister = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
        iziToast.error({
            title: 'Error',
            message: 'Passwords do not match',
        });
        return;
    }

    // Perform validation
    const validationErrors = {};
    if (!formData.name) {
        validationErrors.name = 'Name is required';
    }
    if (!formData.email) {
        validationErrors.email = 'Email is required';
    } else if (!validator.isEmail(formData.email)) {
        validationErrors.email = 'Invalid email address';
    }
    if (!formData.phone) {
        validationErrors.phone = 'Mobile number is required';
    }
    if (!formData.password) {
        validationErrors.password = 'Password is required';
    } else if (formData.password.length < 8) {
        validationErrors.password = 'Password must be at least 8 characters';
    } else if (newPassword !== confirmPassword) {
        validationErrors.confirmPassword = 'Passwords do not match';
    }
    if (!formData.agreeToTerms) {
        validationErrors.agreeToTerms = 'You must agree to the terms and privacy policies';
    }

    // Set validation errors
    setErrors(validationErrors);

    // If there are validation errors, prevent form submission
    if (Object.keys(validationErrors).length > 0) {
        return;
    }

    try {
        const response = await fetch(apipath +'/users/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        const data = await response.json();

        if (response.status === 201) {
            const savedUserId = data.userId;
            const emailId = data.email; 

            iziToast.success({
                title: 'Success',
                message: data.message || 'Registration successful',
                position: 'topCenter',
                timeout: 2000,
            });
            navigate(`/otp-verify?email=${encodeURIComponent(emailId)}`);
        } else if (response.status === 400) {
            iziToast.error({
                title: 'Error',
                message: data.message || 'Email or Mobile No. already exists. Please try another email or mobile no.',
                position: 'topCenter',
            });
        } else {
            iziToast.error({
                title: 'Error',
                message: 'Registration failed. Please try again later.',
                position: 'topCenter',
            });
        }
    } catch (error) {
        iziToast.error({
            title: 'Error',
            message: 'Registration failed. Please try again later.',
            position: 'topCenter',
        });
    }
};

    return (
        <>
            <div className="container-fluid login">
                <div className="row login-row">
                    <div className="col-lg-6">
                        <div className="img-login">
                            <img src="imgs-calendar/password.png" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 login-right register-right">
                        <h1>Register</h1>
                        <p>Welcome to Press Ideas</p>
                        <form onSubmit={handleRegister}>
                        <input type="text" name="role" value={formData.role} onChange={handleChange} hidden/>
                            <div className="login-item col-lg-8 mx-auto">
                                <label htmlFor="name">Name</label>
                                <input type="text"
                                    className={`${errors.name && "is-invalid"}`}
                                    placeholder="Enter Your Full name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    maxLength="25"
                                />
                                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                            </div>
                            <div className="login-item col-lg-8 mx-auto">
                                <label htmlFor="name">Email</label>
                                <input type="text"
                                    className={`${errors.email && "is-invalid"}`}
                                    placeholder="Enter Your Email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    maxLength="50"
                                />
                                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                            </div>
                            <div className="login-item col-lg-8 mx-auto">
                                <label htmlFor="name">Mobile No.</label>
                                <input type="text"
                                    className={`${errors.phone && "is-invalid"}`}
                                    placeholder="Enter Your Mobile No."
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    maxLength="10"
                                    autoComplete="off"
                                    onKeyPress={(event) => {
                                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                                {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                            </div>
                            <div className="login-item col-lg-8 mx-auto">
                                <label htmlFor="name">New Password</label>
                                <div className="input-group login-group">
                                    <input
                                        type={showPassword === 1 ? "text" : "password"}
                                        className={`form-control ${errors.password && "is-invalid"}`}
                                        placeholder="Enter password"
                                        aria-label="password"
                                        aria-describedby="basic-addon2"
                                        name="password"
                                        id="newPassword"
                                        value={newPassword}
                                        onChange={handleNewPasswordChange}
                                    />
                                    <div className="input-group-append login-append">
                                        <span className="input-group-text" id="basic-addon2">
                                            <i className={showPassword === 1 ? "bi bi-eye-slash" : "bi bi-eye"} onClick={() => handleVisibility(1)}></i>
                                        </span>
                                    </div>
                                    {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                </div>
                            </div>
                            <div className="login-item col-lg-8 mx-auto">
                                <label htmlFor="name">Confirm Password</label>
                                <div className="input-group login-group">
                                    <input
                                        type={showPassword === 2 ? "text" : "password"}
                                        className={`form-control ${errors.confirmPassword && "is-invalid"}`}
                                        aria-label="password"
                                        aria-describedby="basic-addon2"
                                        placeholder="Enter your confirm password"
                                        id="confirmPassword"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                    />
                                    <div className="input-group-append login-append">
                                        <span className="input-group-text" id="basic-addon2">
                                            <i className={showPassword === 2 ? "bi bi-eye-slash" : "bi bi-eye"} onClick={() => handleVisibility(2)}></i>
                                        </span>
                                    </div>
                                    {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                                </div>
                            </div>
                            <div className="submit col-lg-8 mx-auto">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="agreeToTerms"
                                        checked={formData.agreeToTerms}
                                        onChange={handleChange}
                                    />{" "}
                                    <Link to="/terms" className="text-black">I agree to the terms and privacy policies</Link>
                                </label>
                                {errors.agreeToTerms && <div className="invalid-feedback d-block">{errors.agreeToTerms}</div>}
                            </div>
                            <div className="login-button col-lg-8 mx-auto">
                                <button>Register</button>
                            </div>
                            <p className="account col-lg-8 mx-auto">
                                Already have an Account ?{" "}
                                <Link to="/login" className="text-decoration-none">
                                    <span>Login</span>
                                </Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
