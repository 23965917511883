// // // import React, { useEffect, useState } from "react";
// // // import Navbar from "../components/Navbar";
// // // import Footer from "../components/Footer";
// // // import axios from "axios";

// // // const OrderTrack = () => {
// // //   const [completeOrders, setCompleteOrders] = useState([]);
// // //   const [isLoading, setIsLoading] = useState(false);

// // //   useEffect(() => {
// // //     fetchCompleteOrders();
// // //   }, []);

// // //   const fetchCompleteOrders = async (orderId) => {
// // //     try {
// // //       setIsLoading(true);
// // //       const response = await axios.get(`https://giftonly4u.com:4000/api/v1/order/details/${orderId}`);
// // //       setCompleteOrders();
// // //       setIsLoading(false);
// // //     } catch (error) {
// // //       console.error('Error fetching complete orders:', error);
// // //       setIsLoading(false);
// // //     }
// // //   };

// // //   const steps = [
// // //     { label: "Order Placed"},
// // //     { label: "Shipped"},
// // //     { label: "Out for Delivery"},
// // //     { label: "Delivered"},
// // //   ];

// // //   const [currentStep, setCurrentStep] = useState(0);

// // //   useEffect(() => {
// // //     const now = new Date();
// // //     const stepIndex = steps.findIndex((step) => now < step.date);
// // //     setCurrentStep(stepIndex === -1 ? steps.length : stepIndex);
// // //   }, []);

// // //   return (
// // //     <>
// // //       <Navbar />

// // //       <div className="container">
// // //         <div className="col-lg-6 mx-auto image-header">
// // //           <h1>Track My Order</h1>
// // //         </div>

// // //         <div className="row track-row">
// // //           <div className="col-lg-12">
// // //             <div className="order-tracker">
// // //               {steps.map((step, index) => (
// // //                 <div key={index} className="step">
// // //                   <div
// // //                     className={`circle ${
// // //                       index < currentStep ? "completed" : ""
// // //                     }`}
// // //                   >
// // //                     {index < currentStep ? "✓" : ""}
// // //                   </div>
// // //                   <div
// // //                     className={`label ${
// // //                       index < currentStep ? "completed" : ""
// // //                     }`}
// // //                   >
// // //                     {step.label}
// // //                     <br />
// // //                   </div>
// // //                   {index < steps.length - 1 && (
// // //                     <div className={`line-container`}>
// // //                       <div
// // //                         className={`line ${
// // //                           index < currentStep - 1 ? "completed" : ""
// // //                         }`}
// // //                       ></div>
// // //                     </div>
// // //                   )}
// // //                 </div>
// // //               ))}
// // //             </div>
// // //           </div>
// // //           <div className="row track-row-2">
// // //             <div className="col-lg-4 track-col">
// // //               <h5>Product Name</h5>
// // //               <h6>Qty : {completeOrders.qty}</h6>
// // //               <p>Price : {completeOrders.totalPrice}</p>
// // //             </div>
// // //             <div className="col-lg-4 track-col">
// // //               <h5>Status  </h5>
// // //               <span>{completeOrders.orderStatus}</span>
// // //             </div>
// // //             <div className="col-lg-4 track-col">
// // //               <h5>Delivery Expected </h5>
// // //               <p>On the way</p>
// // //             </div>
// // //           </div>
// // //           <div className="col-lg-4">
// // //             <div className="track-detail">
// // //               <p>Order Id :  {completeOrders.orderId}</p>
// // //             </div>
// // //           </div>
// // //           <div className="col-lg-4">
// // //             <div className="track-detail">
// // //               <p>Name : Calendar </p>
// // //             </div>
// // //           </div>
// // //           <div className="col-lg-4">
// // //             <div className="track-detail">
// // //               <p>Order Placed on : {new Date(completeOrders.orderDate).toLocaleDateString()}</p>
// // //             </div>
// // //           </div>
// // //           <div className="col-lg-4">
// // //             <div className="track-detail">
// // //               <p>Order Status : {completeOrders.orderStatus} </p>
// // //             </div>
// // //           </div>
// // //           <div className="col-lg-4">
// // //             <div className="track-detail">
// // //               <p>Payment Status : {completeOrders.paymentStatus} </p>
// // //             </div>
// // //           </div>
// // //           <div className="col-lg-4">
// // //             <div className="track-detail">
// // //               <p>Personalized Text : Happy Family</p>
// // //             </div>
// // //           </div>
// // //         </div>
// // //       </div>
// // //       <Footer />
// // //     </>
// // //   );
// // // };

// // // export default OrderTrack;

// // import React, { useEffect, useState } from "react";
// // import Navbar from "../components/Navbar";
// // import Footer from "../components/Footer";
// // import axios from "axios";
// // import { useParams } from "react-router-dom";

// // const OrderTrack = () => {
// //   const [completeOrders, setCompleteOrders] = useState({});
// //   const [isLoading, setIsLoading] = useState(false);
// //   const { orderId } = useParams();

// //   useEffect(() => {
// //     fetchCompleteOrders(orderId);
// //   }, [orderId]);

// //   const fetchCompleteOrders = async () => {
// //     try {
// //       setIsLoading(true);
// //       const response = await axios.get(`https://giftonly4u.com:4000/api/v1/order/details/${orderId}`);
// //       setCompleteOrders(response.data.order);
// //       setIsLoading(false);
// //     } catch (error) {
// //       console.error("Error fetching complete orders:", error);
// //       setIsLoading(false);
// //     }
// //   };

// //   const steps = [
// //     { label: "Order Placed" },
// //     { label: "Dispatched" },
// //     { label: "On the Way" },
// //     { label: "Delivered" }
// //   ];

// //   const getOrderStepIndex = (order) => {
// //     if (order.OntheWay) return 3;
// //     if (order.pickup) return 2;
// //     if (order.dispatched) return 1;
// //     if (order.orderConfirmed) return 0;
// //     return -1;
// //   };

// //   const [currentStep, setCurrentStep] = useState(0);

// //   useEffect(() => {
// //     setCurrentStep(getOrderStepIndex(completeOrders));
// //   }, [completeOrders]);

// //   return (
// //     <>
// //       <Navbar />

// //       <div className="container">
// //         <div className="col-lg-6 mx-auto image-header">
// //           <h1>Track My Order</h1>
// //         </div>

// //         <div className="row track-row">
// //           <div className="col-lg-12">
// //             <div className="order-tracker">
// //               {steps.map((step, index) => (
// //                 <div key={index} className="step">
// //                   <div className={`circle ${index <= currentStep ? "completed" : ""}`}>
// //                     {index <= currentStep ? "✓" : ""}
// //                   </div>
// //                   <div className={`label ${index <= currentStep ? "completed" : ""}`}>
// //                     {step.label}
// //                     <br />
// //                   </div>
// //                   {index < steps.length - 1 && (
// //                     <div className={`line-container`}>
// //                       <div className={`line ${index < currentStep ? "completed" : ""}`}></div>
// //                     </div>
// //                   )}
// //                 </div>
// //               ))}
// //             </div>
// //           </div>

// //           <div className="row track-row-2">
// //             <div className="col-lg-4 track-col">
// //               <h5>Product Name</h5>
// //               <h6>Qty : {completeOrders.qty}</h6>
// //               <p>Price : {completeOrders.totalPrice}</p>
// //             </div>
// //             <div className="col-lg-4 track-col">
// //               <h5>Status</h5>
// //               <span>{completeOrders.orderStatus}</span>
// //             </div>
// //             <div className="col-lg-4 track-col">
// //               <h5>Delivery Expected</h5>
// //               <p>On the way</p>
// //             </div>
// //           </div>
// //           <div className="col-lg-4">
// //             <div className="track-detail">
// //               <p>Order Id : {completeOrders.orderId}</p>
// //             </div>
// //           </div>
// //           <div className="col-lg-4">
// //             <div className="track-detail">
// //               <p>Name : Calendar</p>
// //             </div>
// //           </div>
// //           <div className="col-lg-4">
// //             <div className="track-detail">
// //               <p>Order Placed on : {new Date(completeOrders.orderPlacedDate).toLocaleDateString()}</p>
// //             </div>
// //           </div>
// //           <div className="col-lg-4">
// //             <div className="track-detail">
// //               <p>Order Status : {completeOrders.orderStatus}</p>
// //             </div>
// //           </div>
// //           <div className="col-lg-4">
// //             <div className="track-detail">
// //               <p>Payment Status : {completeOrders.paymentStatus}</p>
// //             </div>
// //           </div>
// //           <div className="col-lg-4">
// //             <div className="track-detail">
// //               <p>Personalized Text : Happy Family</p>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //       <Footer />
// //     </>
// //   );
// // };

// // export default OrderTrack;


// import React, { useEffect, useState } from "react";
// import Navbar from "../components/Navbar";
// import Footer from "../components/Footer";
// import axios from "axios";
// import { Link, useParams } from "react-router-dom";
// import { useAuth } from "../AuthContext";

// const OrderTrack = () => {
//   const [completeOrders, setCompleteOrders] = useState({});
//   const { calendarId } = useParams();
//   const [isLoading, setIsLoading] = useState(false);
//   const { orderId } = useParams();
//   const { apipath } = useAuth();
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
  
//    // Function to check if user is logged in
//    const checkLoginStatus = () => {
//     const token = localStorage.getItem('token');
//     setIsLoggedIn(!!token); // Set login status based on token presence
// };

// useEffect(() => {
//     checkLoginStatus(); // Check login status on component mount
// }, []);

//   useEffect(() => {
//     fetchCompleteOrders(orderId);
//   }, [orderId]);

//   const fetchCompleteOrders = async (orderId) => {
//     try {
//       setIsLoading(true);
//       const response = await axios.get(apipath + `/order/details/${orderId}`);
//       setCompleteOrders(response.data.order);
//       console.log(response.data.order);
//       setIsLoading(false);
//     } catch (error) {
//       console.error("Error fetching complete orders:", error);
//       setIsLoading(false);
//     }
//   };

//   const steps = [
//     { label: "Order Placed", key: "orderConfirmed" },
//     { label: "Dispatched", key: "dispatched" },
//     { label: "On the Way", key: "OntheWay" },
//     { label: "Delivered", key: "pickup" }
//   ];

//   const getCurrentStep = (order) => {
//     for (let i = steps.length - 1; i >= 0; i--) {
//       if (order[steps[i].key]) return i;
//     }
//     return -1;
//   };

//   const [currentStep, setCurrentStep] = useState(0);

//   useEffect(() => {
//     setCurrentStep(getCurrentStep(completeOrders));
//   }, [completeOrders]);

//   return (
//     <>
//       <Navbar isLoggedIn={isLoggedIn}/>

//       <div className="container">
//         <div className="col-lg-6 mx-auto image-header">
//           <h1>Track My Order</h1>
//         </div>

//         <div className="row track-row">
//           <div className="col-lg-12">
//             <div className="order-tracker">
//               {steps.map((step, index) => (
//                 <div key={index} className="step">
//                   <div className={`circle ${index <= currentStep ? "completed" : ""}`}>
//                     {index <= currentStep ? "✓" : ""}
//                   </div>
//                   <div className={`label ${index <= currentStep ? "completed" : ""}`}>
//                     {step.label}
//                     <br />
//                   </div>
//                   {index < steps.length - 1 && (
//                     <div className={`line-container`}>
//                       <div className={`line ${index < currentStep ? "completed" : ""}`}></div>
//                     </div>
//                   )}
//                 </div>
//               ))}
//               <Link to={`/printableCalendar/${calendarId}`}>
//               <button>
//                 <i className="bi bi-download"></i> Download
//               </button>
//             </Link>
//             </div>
//           </div>

//           <div className="row track-row-2">
//             <div className="col-lg-4 track-col">
//               <h5>Product Name</h5>
//               <h6>Qty : {completeOrders.qty}</h6>
//               <p>Price : {completeOrders.totalPrice}</p>
//             </div>
//             <div className="col-lg-4 track-col">
//               <h5>Status</h5>
//               <span>{completeOrders.orderStatus}</span>
//             </div>
//             <div className="col-lg-4 track-col">
//               <h5>Delivery Expected</h5>
//               <p>{completeOrders.pickup ? "Done" : "On the way"}</p>
//             </div>
//           </div>
//           <div className="col-lg-4">
//             <div className="track-detail">
//               <p>Order Id : {completeOrders.orderId}</p>
//             </div>
//           </div>
//           <div className="col-lg-4">
//             <div className="track-detail">
//               <p>Name : Calendar</p>
//             </div>
//           </div>
//           <div className="col-lg-4">
//             <div className="track-detail">
//               <p>Order Placed on : {new Date(completeOrders.orderPlacedDate).toLocaleDateString()}</p>
//             </div>
//           </div>
//           <div className="col-lg-4">
//             <div className="track-detail">
//               <p>Order Status : {completeOrders.orderStatus}</p>
//             </div>
//           </div>
//           <div className="col-lg-4">
//             <div className="track-detail">
//               <p>Payment Status : {completeOrders.paymentStatus}</p>
//             </div>
//           </div>
//           <div className="col-lg-4">
//             <div className="track-detail">
//               <p>Personalized Text : Happy Family</p>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default OrderTrack;

import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../AuthContext";

const OrderTrack = () => {
  const [completeOrders, setCompleteOrders] = useState({});
  const { orderId } = useParams();
  const { apipath } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [calendarId, setCalendarId] = useState(null);

  // Function to check if user is logged in
  const checkLoginStatus = () => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token); // Set login status based on token presence
  };

  useEffect(() => {
    checkLoginStatus(); // Check login status on component mount
  }, []);

  useEffect(() => {
    fetchCompleteOrders(orderId);
  }, [orderId]);

  const fetchCompleteOrders = async (orderId) => {
    try {
      setIsLoading(true);
      const response = await axios.get(apipath + `/order/details/${orderId}`);
      setCompleteOrders(response.data.order);
      setCalendarId(response.data.order.calendar_id); // Set calendar_id state
      console.log(response.data.order);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching complete orders:", error);
      setIsLoading(false);
    }
  };

  const steps = [
    { label: "Order Placed", key: "orderConfirmed" },
    { label: "Dispatched", key: "dispatched" },
    { label: "On the Way", key: "OntheWay" },
    { label: "Delivered", key: "pickup" }
  ];

  const getCurrentStep = (order) => {
    for (let i = steps.length - 1; i >= 0; i--) {
      if (order[steps[i].key]) return i;
    }
    return -1;
  };

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    setCurrentStep(getCurrentStep(completeOrders));
  }, [completeOrders]);

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} />

      <div className="container">
      
        <div className="col-lg-6 mx-auto image-header">
          <h1>Track My Order</h1>
        </div>

        <div className="row track-row">
        {calendarId && (
                <div className="congrats-button orderT-button">
                <Link to={`/printableCalendar/${calendarId}`}>
                  <button>
                    <i className="bi bi-download"></i> Download
                  </button>
                </Link>
                </div>
              )}
          <div className="col-lg-12">
            <div className="order-tracker">
              {steps.map((step, index) => (
                <div key={index} className="step">
                  <div className={`circle ${index <= currentStep ? "completed" : ""}`}>
                    {index <= currentStep ? "✓" : ""}
                  </div>
                  <div className={`label ${index <= currentStep ? "completed" : ""}`}>
                    {step.label}
                    <br />
                  </div>
                  {index < steps.length - 1 && (
                    <div className={`line-container`}>
                      <div className={`line ${index < currentStep ? "completed" : ""}`}></div>
                    </div>
                  )}
                </div>
              ))}
              
            </div>
          </div>

          <div className="row track-row-2">
            <div className="col-lg-4 track-col">
              <h5>Product Name</h5>
              <h6>Qty : {completeOrders.qty}</h6>
              <p>Price : Rs. {completeOrders.totalPrice}</p>
            </div>
            <div className="col-lg-4 track-col">
              <h5>Status</h5>
              <span>{completeOrders.orderStatus}</span>
            </div>
            <div className="col-lg-4 track-col">
              <h5>Delivery Expected</h5>
              <p>{completeOrders.pickup ? "Done" : "On the way"}</p>
              <h6>Courier Id : {completeOrders.courierId}</h6>
              <p>Courier Name : {completeOrders.courierName}</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="track-detail">
              <p>Order Id : {completeOrders.orderId}</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="track-detail">
              <p>Name : Calendar</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="track-detail">
              <p>Order Placed on : {new Date(completeOrders.orderPlacedDate).toLocaleDateString()}</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="track-detail">
              <p>Order Status : {completeOrders.orderStatus}</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="track-detail">
              <p>Payment Status : {completeOrders.paymentStatus}</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="track-detail">
              <p>Personalized Text : Happy Family</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OrderTrack;

