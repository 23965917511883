import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../AuthContext";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import Select from 'react-select';

const ShippingAndBilling = () => {
  const { calendarId } = useParams();
  const navigate = useNavigate();
  const { apipath } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSameAddress, setIsSameAddress] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
    fetchUserDetails();
  }, []);

  const [userDetails, setUserDetails] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    country: "",
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
    paymentMethod: "UPI",
    additionalInformation: "",
    calendar_id: calendarId || "",
    flat: "",
    houseNo: "",
    building: "",
    company: "",
    apartment: "",
    area: "",
    street: "",
    sector: "",
    landmark: "",
  });

  const [shippingData, setShippingData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    alternatePhone: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
    paymentMethod: "UPI",
    additionalInformation: "",
    calendar_id: calendarId || "",
    flat: "",
    houseNo: "",
    building: "",
    company: "",
    apartment: "",
    area: "",
    street: "",
    sector: "",
    landmark: "",
  });

  const [calendarData, setCalendarData] = useState({
    billing_id: "",
    shipping_id: "",
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [selectedShippingCountry, setSelectedShippingCountry] = useState(null);
  const [selectedShippingState, setSelectedShippingState] = useState(null);
  const [selectedShippingCity, setSelectedShippingCity] = useState(null);

  // Reset state and city when country changes
  useEffect(() => {
    if (selectedCountry) {
      // setSelectedState(null);
      // setSelectedCity(null);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      // setSelectedCity(null);
    }
  }, [selectedState]);

  // Reset shipping state and city when shipping country changes
  useEffect(() => {
    if (selectedShippingCountry) {
      // setSelectedShippingState(null);
      // setSelectedShippingCity(null);
    }
  }, [selectedShippingCountry]);

  useEffect(() => {
    if (selectedShippingState) {
      // setSelectedShippingCity(null);
    }
  }, [selectedShippingState]);

  useEffect(() => {
  }, [selectedCountry, selectedState, selectedCity]);

  const handleSelectChange = (name, value, isShipping = false) => {
    if (isShipping) {
      setShippingData((prevState) => ({
        ...prevState,
        [name]: value?.name || "",
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value?.name || "",
      }));
    }
  };

  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(apipath + `/users/details/${userId}`);
      if (response.data.user) {
        const user = response.data.user;
        setUserDetails(user);
        // Populate formData with user details
        setFormData({
          firstName: user.name,
          lastName: user.lastName,
          phone: user.phone,
          email: user.email,
          address: user.address,
          city: user.city,
          state: user.state,
          country: user.country,
          paymentMethod: "UPI", // or any default value you want to set
          additionalInformation: user.additionalInformation,
          calendar_id: calendarId || "",
          flat: user.flat,
          houseNo: user.houseNo,
          building: user.building,
          company: user.company,
          apartment: user.apartment,
          area: user.area,
          street: user.street,
          sector: user.sector,
          landmark: user.landmark,
        });
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Update billing data
    setFormData({
      ...formData,
      [name]: value,
    });
  
    // If same address is checked, also update shipping data
    if (isSameAddress) {
      setShippingData({
        ...shippingData,
        [name]: value,
      });
    }
  };
  
  const handleShippingInputChange = (e) => {
    const { name, value } = e.target;
  
    // Update shipping data
    setShippingData({
      ...shippingData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setIsSameAddress(e.target.checked);
    if (e.target.checked) {
      setShippingData({ ...formData });
      setSelectedShippingCountry(selectedCountry);
      setSelectedShippingState(selectedState);
      setSelectedShippingCity(selectedCity);
    } else {
      setShippingData({
        firstName: "",
        lastName: "",
        phone: "",
        alternatePhone: "",
        address: "",
        city: "",
        state: "",
        country: "",
        pinCode: "",
        paymentMethod: "UPI",
        additionalInformation: "",
        calendar_id: calendarId || "",
        flat: "",
        houseNo: "",
        building: "",
        company: "",
        apartment: "",
        area: "",
        street: "",
        sector: "",
        landmark: "",
      });
      setSelectedShippingCountry(null);
      setSelectedShippingState(null);
      setSelectedShippingCity(null);
    }
  };
  
  const handleShipping = async () => {
    try {
      const shippingResponse = await fetch(apipath + '/shipping/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(shippingData),
      });
      const responseData = await shippingResponse.json();
      const savedShippingId = responseData.shippingInformation._id;
      return savedShippingId;
    } catch (error) {
      console.error('Error adding shipping information:', error);
    }
  };

  const handleBilling = async () => {
    try {
      const billingResponse = await fetch(apipath + '/billing/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (billingResponse.status === 201) {
        const responseData = await billingResponse.json();
        const savedBillingId = responseData.billingInformation._id;
        return savedBillingId;
      } else {
        iziToast.error({
          message: 'Billing information addition failed',
          position: 'topCenter',
        });
        return null;
      }
    } catch (error) {
      console.error('Error adding billing information:', error);
      iziToast.error({
        message: 'An error occurred',
        position: 'topCenter',
      });
      return null;
    }
  };

  // const handleRegister = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const billingId = await handleBilling();
  //     let shippingId = null;

  //     if (isSameAddress) {
  //       shippingId = await handleShipping();
  //     }

  //     if (billingId) {
  //       setCalendarData({
  //         billing_id: billingId,
  //         shipping_id: shippingId || "",
  //       });

  //       // Update the calendar with the billing and shipping IDs
  //       await handleCalendarUpdate(billingId, shippingId);

  //       navigate(`/preview1/${calendarId}/${shippingId}`); // Pass billingId to handleOrderAdd
  //     }
  //   } catch (error) {
  //     console.error('Error registering:', error);
  //   }
  // };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const billingId = await handleBilling(); // Save billing data
      let shippingId = null;
  
      // Always handle shipping data, whether same as billing or not
      if (isSameAddress) {
        shippingId = await handleShipping(); // Save shipping data if the address is the same
      } else {
        // Save shipping data separately when the checkbox is not selected
        shippingId = await handleShipping();
      }
  
      if (billingId && shippingId) {
        setCalendarData({
          billing_id: billingId,
          shipping_id: shippingId,
        });
  
        // Update the calendar with billing and shipping IDs
        await handleCalendarUpdate(billingId, shippingId);
  
        // Redirect to the next page
        navigate(`/preview1/${calendarId}/${shippingId}`);
      }
    } catch (error) {
      console.error('Error registering:', error);
    }
  };
  

  const handleCalendarUpdate = async (billingId, shippingId) => {
    try {
      await axios.put(
        apipath + `/calendar/details/${calendarId}`,
        { billing_id: billingId, shipping_id: shippingId },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      iziToast.success({
        message: 'Calendar updated successfully',
        position: 'topCenter',
      });
    } catch (error) {
      console.error("Error updating calendar:", error);
      iziToast.error({
        message: 'Error updating calendar',
        position: 'topCenter',
      });
    }
  };

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} />
      <div className="container">
        <div className="col-lg-6 mx-auto image-header">
          <h1>Billing & Shipping Information</h1>
        </div>
        <div className="image-content">
          <h3>Please enter your details:</h3>
          <form onSubmit={handleRegister}>
            <div className="row bill-row py-3">
              <div className="col-lg-6">
                <h4>Billing Information :</h4>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">*First Name</label>
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      maxLength="25"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Last Name</label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      maxLength="25"
                      // required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">*Email</label>
                    <input
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      maxLength="50"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">*Mobile No.</label>
                    <input
                      type="text"
                      placeholder="Enter Phone Number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      maxLength="10"
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                          event.preventDefault();
                        }
                      }}
                      required
                    />
                  </div>
                </div>
                {/* <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Flat</label>
                    <input
                      type="text"
                      placeholder="Flat"
                      name="flat"
                      value={formData.flat}
                      onChange={handleInputChange}
                      maxLength="100"
                      // required
                    />
                  </div>
                </div> */}
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Flat/House No.</label>
                    <input
                      type="text"
                      placeholder="House No."
                      name="houseNo"
                      value={formData.houseNo}
                      onChange={handleInputChange}
                      maxLength="100"
                      // required
                    />
                  </div>
                </div>
                {/* <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Building Name </label>
                    <input
                      type="text"
                      placeholder="Building Name"
                      name="building"
                      value={formData.building}
                      onChange={handleInputChange}
                      maxLength="100"
                      // required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Company Name</label>
                    <input
                      type="text"
                      placeholder="Company Name"
                      name="company"
                      value={formData.company}
                      onChange={handleInputChange}
                      maxLength="100"
                      // required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Apartment</label>
                    <input
                      type="text"
                      placeholder="apartment"
                      name="apartment"
                      value={formData.apartment}
                      onChange={handleInputChange}
                      maxLength="100"
                      // required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Area</label>
                    <input
                      type="text"
                      placeholder="Area"
                      name="area"
                      value={formData.area}
                      onChange={handleInputChange}
                      maxLength="100"
                      // required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Street</label>
                    <input
                      type="text"
                      placeholder="Street"
                      name="street"
                      value={formData.street}
                      onChange={handleInputChange}
                      maxLength="100"
                      // required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Sector</label>
                    <input
                      type="text"
                      placeholder="Sector"
                      name="sector"
                      value={formData.sector}
                      onChange={handleInputChange}
                      maxLength="100"
                      // required
                    />
                  </div>
                </div> */}
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">*Address</label>
                    <input
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Landmark</label>
                    <input
                      type="text"
                      placeholder="Landmark"
                      name="landmark"
                      value={formData.landmark}
                      onChange={handleInputChange}
                      maxLength="100"
                      // required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">*Country</label>
                    <Select
                      id="country"
                      options={Country.getAllCountries()}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode}
                      value={selectedCountry}
                      onChange={(item) => {
                        setSelectedCountry(item);
                        handleSelectChange('country', item);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">*State</label>
                    <Select
                      id="state"
                      options={State.getStatesOfCountry(selectedCountry?.isoCode || '')}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode}
                      value={selectedState}
                      onChange={(item) => {
                        setSelectedState(item);
                        handleSelectChange('state', item);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">*City</label>
                    <Select
                      id="city"
                      options={City.getCitiesOfState(selectedState?.countryCode || '', selectedState?.isoCode || '')}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      value={selectedCity}
                      onChange={(item) => {
                        setSelectedCity(item);
                        handleSelectChange('city', item);
                      }} 
                      required
                    /></div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">*Pin Code</label>
                    <input
                      type="text"
                      placeholder="Enter your pin code"
                      name="pinCode"
                      value={formData.pinCode}
                      onChange={handleInputChange}
                      maxLength="6"
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                          event.preventDefault();
                        }
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Additional Information</label>
                    <textarea
                      type="text"
                      placeholder="Any additional Info"
                      name="additionalInformation"
                      value={formData.additionalInformation}
                      onChange={handleInputChange}
                      maxLength="100"
                    // required
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h4>
                  <input
                    type="checkbox"
                    checked={isSameAddress}
                    onChange={handleCheckboxChange}
                  /> Shipping address same as billing address
                </h4>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">*First Name</label>
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={shippingData.firstName}
                      onChange={handleShippingInputChange}
                      maxLength="25"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Last Name</label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={shippingData.lastName}
                      onChange={handleShippingInputChange}
                      maxLength="25"
                      // required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">*Mobile No.</label>
                    <input
                      type="text"
                      placeholder="Enter Phone Number"
                      name="phone"
                      value={shippingData.phone}
                      onChange={handleShippingInputChange}
                      maxLength="10"
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                          event.preventDefault();
                        }
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Alternate Phone no.</label>
                    <input
                      type="text"
                      placeholder="Enter Number"
                      name="alternatePhone"
                      value={shippingData.alternatePhone}
                      onChange={handleShippingInputChange}
                      maxLength="10"
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                {/* <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Flat</label>
                    <input
                      type="text"
                      placeholder="Flat"
                      name="flat"
                      value={shippingData.flat}
                      onChange={handleShippingInputChange}
                      maxLength="25"
                      // required
                    />
                  </div>
                </div> */}
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Flat/House No.</label>
                    <input
                      type="text"
                      placeholder="House No."
                      name="houseNo"
                      value={shippingData.houseNo}
                      onChange={handleShippingInputChange}
                      maxLength="25"
                      // required
                    />
                  </div>
                </div>
{/* 
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Building Name</label>
                    <input
                      type="text"
                      placeholder="Building Name"
                      name="building"
                      value={shippingData.building}
                      onChange={handleShippingInputChange}
                      maxLength="25"
                      // required
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Company Name</label>
                    <input
                      type="text"
                      placeholder="Company Name"
                      name="company"
                      value={shippingData.company}
                      onChange={handleShippingInputChange}
                      maxLength="25"
                      // required
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Apartment</label>
                    <input
                      type="text"
                      placeholder="Apartment"
                      name="apartment"
                      value={shippingData.apartment}
                      onChange={handleShippingInputChange}
                      maxLength="25"
                      // required
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Area</label>
                    <input
                      type="text"
                      placeholder="Area"
                      name="area"
                      value={shippingData.area}
                      onChange={handleShippingInputChange}
                      maxLength="25"
                      // required
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Street</label>
                    <input
                      type="text"
                      placeholder="Street"
                      name="street"
                      value={shippingData.street}
                      onChange={handleShippingInputChange}
                      maxLength="25"
                      // required
                    />
                  </div>
                </div>

                 <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Sector</label>
                    <input
                      type="text"
                      placeholder="Sector"
                      name="sector"
                      value={shippingData.sector}
                      onChange={handleShippingInputChange}
                      maxLength="25"
                      // required
                    />
                  </div>
                </div> */}

                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">*Address</label>
                    <input
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={shippingData.address}
                      onChange={handleShippingInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Landmark</label>
                    <input
                      type="text"
                      placeholder="Landmark"
                      name="landmark"
                      value={shippingData.landmark}
                      onChange={handleShippingInputChange}
                      maxLength="100"
                      // required
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">*Country</label>
                    <Select
                      id="shipping-country"
                      options={Country.getAllCountries()}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode}
                      value={selectedShippingCountry}
                      onChange={(item) => {
                        setSelectedShippingCountry(item);
                        handleSelectChange("country", item, true);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">*State</label>
                    <Select
                      id="shipping-state"
                      options={State.getStatesOfCountry(selectedShippingCountry?.isoCode || "")}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode}
                      value={selectedShippingState}
                      onChange={(item) => {
                        setSelectedShippingState(item);
                        handleSelectChange("state", item, true);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">*City</label>
                    <Select
                      id="shipping-city"
                      options={City.getCitiesOfState(selectedShippingState?.countryCode || "", selectedShippingState?.isoCode || "")}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      value={selectedShippingCity}
                      onChange={(item) => {
                        setSelectedShippingCity(item);
                        handleSelectChange("city", item, true);
                      }}
                      required
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">*Pin Code</label>
                    <input
                      type="text"
                      placeholder="Enter your pin code"
                      name="pinCode"
                      value={shippingData.pinCode}
                      onChange={handleShippingInputChange}
                      maxLength="6"
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                          event.preventDefault();
                        }
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Additional Information</label>
                    <textarea
                      type="text"
                      placeholder="Any additional Info"
                      name="additionalInformation"
                      value={shippingData.additionalInformation}
                      onChange={handleShippingInputChange}
                      maxLength="100"
                    // required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="image-button">
              <button type="submit">
                Add
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ShippingAndBilling;