import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import iziToast from 'izitoast';

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { logout } = useAuth();
  const userId = localStorage.getItem('userId');
  const location = useLocation();
  const navigate = useNavigate();

  // Check login status on mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  // Handle redirect based on authentication status
  const handleRedirect = (path) => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate(path);
    } else {
      localStorage.setItem('redirectAfterLogin', path);
      navigate('/login');
    }
  };

  const handleLogout = () => {
    logout(); // Call logout function from AuthContext
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');
    
    iziToast.success({
      message: "Logout successful",
      position: "topCenter",
    });

    navigate('/login'); // Redirect to login page
  };

  const isActive = (path) => location.pathname === path ? 'active' : '';

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top">
      <div className="container nav-fluid">
        <Link className="navbar-brand" to="/">
          <img src={`${process.env.PUBLIC_URL}/imgs-calendar/logo1.png`} alt="" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link className={`nav-link ${isActive('/')}`} to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link btn ${isActive('/image-upload')}`}
                onClick={() => handleRedirect('/image-upload')}
              >
                Events
              </button>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${isActive('/calendarPrice')}`} to="/calendarPrice">
                Pricing
              </Link>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link btn ${isActive(`/orders/${userId}`)}`}
                onClick={() => handleRedirect(`/orders/${userId}`)}
              >
                Orders
              </button>
            </li>
          </ul>
          <div className="d-flex nav-buttons">
            {isLoggedIn ? (
              <div className="d-flex nav-buttons nav-item">
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/imgs-calendar/profile.png`}
                    alt=""
                  />
                </div>
                <h6 className="pt-2">
                  {localStorage.getItem('name')}
                </h6>
                <i className="bi bi-chevron-down"></i>
                <div className="dropdown-menu">
                  <ul>
                    <li>
                      <Link to={`/profile/${userId}`}>My Profile</Link>
                    </li>
                    <li>
                      <Link to={`/orders/${userId}`}>My Orders</Link>
                    </li>
                    <li>
                      <Link to="#" onClick={handleLogout}>Logout</Link>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <Link to="/login">
                <button className="first-btn me-3" type="submit">Login →</button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
