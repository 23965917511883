import './App.css';
import Landing from './pages/Landing';

import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";
import Login from './loginReg/Login';
import Register from './loginReg/Register';
import Forgot from './loginReg/Forgot';
import Reset from './loginReg/Reset';
import Sms from './loginReg/Sms';
import Otp from './loginReg/Otp';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Refund from './pages/Refund';
import Image from './pages/Image';
import Events from './pages/Events';
import Preview from './pages/Preview';
import Page1 from './PreviewPages/Page1';
import Page2 from './PreviewPages/Page2';
import Page3 from './PreviewPages/Page3';
import Page4 from './PreviewPages/Page4';
import Page15 from './PreviewPages/Page15';
import Page16 from './PreviewPages/Page16';
import Billing from './pages/Billing';
import BillSummary from './pages/BillSummary';
import Congrats from './pages/Congrats';
import Profile from './loginReg/Profile';
import Orders from './pages/Orders';
import ReactBigCalendar from './PreviewPages/ReactBigCalendar';
import Portrait from './PreviewPages/Portrait';
import Landscape from './PreviewPages/Landscape';
import AdminDashboard from './admin/AdminDashboard';
import CustomerDetails from './admin/CustomerDetails';
import ImageEdit from './pages/ImageEdit';
import CompleteOrder from './admin/CompleteOrder';
import PendingOrder from './admin/PendingOrder';
import BillingInformation from './admin/BillingInformation';
import CalendarDetails from './admin/CalendarDetails';
import January from './calendarPage/january';
import February from './calendarPage/february';
import March from './calendarPage/march';
import April from './calendarPage/april';
import May from './calendarPage/may';
import June from './calendarPage/june';
import July from './calendarPage/july';
import August from './calendarPage/august';
import September from './calendarPage/september';
import October from './calendarPage/october';
import November from './calendarPage/november';
import December from './calendarPage/december';
import OrderTrack from './pages/OrderTrack';
import PrintableCalendar from './PreviewPages/PrintableCalendar';
import PaymentPage from './pages/PaymentPage';
import GoogleCalendar from './pages/GoogleCalendar';
import ShoppingPolicy from './pages/ShoppingPolicy';
import FaqsDetails from './admin/FaqsDetails';
import FAQsAdd from './admin/FaqsAdd';
import FAQsEdit from './admin/FaqsEdit';
import CalendarPrice from './pages/CalendarPrice';
import RegOtpVerify from './loginReg/RegOtpVerify';
import ShippingAndBilling from './pages/ShippingAndBilling';
import PortraitLandscape from './PreviewPages/PortraitLandscape';
import CalendarPortrait from './PreviewPages/CalendarPortrait';
import CalendarLandscape from './PreviewPages/CalendarLandscape';
import ShippingDetails from './admin/ShippingDetails';
import Test from './PreviewPages/Test';
import AdminPrintCalendar from './PreviewPages/AdminPrintCalendar';
import { useAuth } from './AuthContext';
import { useEffect, useState } from 'react';
import axios from 'axios';
import MonthPage from './PreviewPages/MonthPage';
import CalendarViewPrint from './PreviewPages/CalendarViewPrint';
import PrintManagementDetails from './admin/PrintManagementDetails';
import PrintManagementAdd from './admin/PrintManagementAdd';
import RegVerification from './loginReg/RegVerification';
import PrintManagementEdit from './admin/PrintManagementEdit';
import CorporateDetails from './admin/CorporateDetails';
import CorporateAdd from './admin/CorporateAdd';
import CorporateEdit from './admin/CorporateEdit';

function App() {
  // const { calendarId, billingId } = useParams();
  // const { filepath, apipath } = useAuth();
  // const [calendarData, setCalendarData] = useState(null);

  // useEffect(() => {
  //   // Fetch the calendar data from your API
  //   axios.get(apipath + `/calendar/details/66bd7f4f0bb8b4505dec1a94`)
  //     .then(response => {
  //       if (response.data.success) {
  //         setCalendarData(response.data.calendar);
  //       }
  //     })
  //     .catch(error => {
  //       console.error("There was an error fetching the calendar data!", error);
  //     });
  // }, []);

  // const renderRoutes = () => {
  //   if (!calendarData) return null;

  //   const routes = [];

  //   if (calendarData.january) {
  //     routes.push(<Route key="january" path={`/january/:calendarId/:billingId`} element={<January />} />);
  //   }
  //   if (calendarData.february) {
  //     routes.push(<Route key="february" path={`/february/:calendarId/:billingId`} element={<February />} />);
  //   }
  //   if (calendarData.march) {
  //     routes.push(<Route key="march" path={`/march/:calendarId/:billingId`} element={<March />} />);
  //   }
  //   if (calendarData.april) {
  //     routes.push(<Route key="april" path={`/april/:calendarId/:billingId`} element={<April />} />);
  //   }
  //   if (calendarData.may) {
  //     routes.push(<Route key="may" path={`/may/:calendarId/:billingId`} element={<May />} />);
  //   }
  //   if (calendarData.june) {
  //     routes.push(<Route key="june" path={`/june/:calendarId/:billingId`} element={<June />} />);
  //   }
  //   if (calendarData.july) {
  //     routes.push(<Route key="july" path={`/july/:calendarId/:billingId`} element={<July />} />);
  //   }
  //   if (calendarData.august) {
  //     routes.push(<Route key="august" path={`/august/:calendarId/:billingId`} element={<August />} />);
  //   }
  //   if (calendarData.september) {
  //     routes.push(<Route key="september" path={`/september/:calendarId/:billingId`} element={<September />} />);
  //   }
  //   if (calendarData.october) {
  //     routes.push(<Route key="october" path={`/october/:calendarId/:billingId`} element={<October />} />);
  //   }
  //   if (calendarData.november) {
  //     routes.push(<Route key="november" path={`/november/:calendarId/:billingId`} element={<November />} />);
  //   }
  //   if (calendarData.december) {
  //     routes.push(<Route key="december" path={`/december/:calendarId/:billingId`} element={<December />} />);
  //   }

  //   return routes;
  // };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/forgot" element={<Forgot />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/otp" element={<Sms />} />
          <Route exact path="/otp-verifys" element={<Otp />} />
          <Route exact path="/otp-verify" element={<RegOtpVerify />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/refund" element={<Refund />} />
          <Route exact path="/image-upload" element={<Image />} />
          <Route exact path="/events/:calendarId" element={<Events />} />
          <Route exact path="/preview/:calendarId" element={<Preview />} />
          <Route exact path="/image-edit/:calendarId" element={<ImageEdit />} />
          <Route exact path="/billing/:calendarId/:billingId" element={<Billing />} />
          <Route exact path="/shipping-billing/:calendarId" element={<ShippingAndBilling />} />
          {/* <Route exact path="/bill-summary/:calendarId/:orderId" element={<BillSummary />} /> */} 
          <Route path="/bill-summary/:calendarId/:orderId" element={<BillSummary />} />
          <Route exact path="/congrats/:calendarId" element={<Congrats />} />
          <Route exact path="/profile/:userId" element={<Profile />} />
          <Route exact path="/orders/:userId" element={<Orders />} />
          <Route exact path="/order-track/:orderId" element={<OrderTrack />} />
          <Route exact path="/shoppingPolicy" element={<ShoppingPolicy />} />
          <Route exact path="/calendarPrice" element={<CalendarPrice />} />
          <Route exact path="/faqsDetails" element={<FaqsDetails />} />
          <Route exact path="/fAQsAdd" element={<FAQsAdd />} />
          <Route exact path="/fAQsEdit/:faqsId" element={<FAQsEdit />} />

          <Route path="/:monthName/:calendarId/:shippingId" element={<MonthPage />} />
          
          <Route exact path="/preview1" element={<Page1 />} />
          <Route exact path="/preview2" element={<Page2 />} />
          <Route exact path="/preview3" element={<Page3 />} />
          <Route exact path="/preview4" element={<Page4 />} />
          <Route exact path="/preview15" element={<Page15 />} />
          <Route exact path="/preview16" element={<Page16 />} />
          <Route exact path="/calendar" element={<ReactBigCalendar />} />
          <Route exact path="/portrait/:calendarId" element={<Portrait />} />
          <Route exact path="/landscape/:calendarId" element={<Landscape />} />
          <Route exact path="/printableCalendar/:calendarId" element={<PrintableCalendar />} />
          <Route exact path="/adminPrintCalendar/:calendarId" element={<AdminPrintCalendar />} />
          <Route exact path="/calendarPortrait" element={<CalendarPortrait />} />
          <Route exact path="/calendarLandscape" element={<CalendarLandscape />} />
          <Route exact path="/shippingDetails" element={<ShippingDetails />} />
          <Route exact path="/print-management" element={<PrintManagementDetails />} />
          <Route exact path="/add-print-admin" element={<PrintManagementAdd />} />
          <Route exact path="/edit-print-admin/:subAdminId" element={<PrintManagementEdit />} />
          <Route exact path="/reg-verification" element={<RegVerification />} />

          
          {/* <Route exact path="/january/:calendarId/:billingId" element={<January />} />
          <Route exact path="/february/:calendarId/:billingId" element={<February />} />
          <Route exact path="/march/:calendarId/:billingId" element={<March />} />
          <Route exact path="/april/:calendarId/:billingId" element={<April />} />
          <Route exact path="/may/:calendarId/:billingId" element={<May />} />
          <Route exact path="/june/:calendarId/:billingId" element={<June />} />
          <Route exact path="/july/:calendarId/:billingId" element={<July />} />
          <Route exact path="/august/:calendarId/:billingId" element={<August />} />
          <Route exact path="/september/:calendarId/:billingId" element={<September />} />
          <Route exact path="/october/:calendarId/:billingId" element={<October />} />
          <Route exact path="/november/:calendarId/:billingId" element={<November />} />
          <Route exact path="/december/:calendarId/:billingId" element={<December />} /> */}
          {/* {renderRoutes()} */}

          <Route exact path="/preview1/:calendarId/:shippingId" element={<Page1 />} />
          <Route exact path="/preview2/:calendarId/:shippingId" element={<Page2 />} />
          <Route exact path="/preview3/:calendarId" element={<Page3 />} />
          <Route exact path="/preview4/:calendarId" element={<Page4 />} />
          <Route exact path="/preview15/:calendarId/:shippingId" element={<Page15 />} />
          <Route exact path="/calendar-view-print/:calendarId/:shippingId" element={<CalendarViewPrint />} />
          <Route exact path="/preview16/:calendarId/:shippingId" element={<Page16 />} />

          <Route exact path="/adminDashboard" element={<AdminDashboard />} />          
          <Route exact path="/customerDetails" element={<CustomerDetails />} />          
          <Route exact path="/completeOrder" element={<CompleteOrder />} />          
          <Route exact path="/pendingOrder" element={<PendingOrder />} />          
          <Route exact path="/billingInformation" element={<BillingInformation />} />          
          <Route exact path="/calendarDetails" element={<CalendarDetails />} />         
          <Route exact path="/paymentPage" element={<PaymentPage />} />         
          <Route exact path="/googleCalendar" element={<GoogleCalendar />} />         
          <Route exact path="/portraitLandscape/:calendarId" element={<PortraitLandscape />} />         
          <Route exact path="/corporate" element={<CorporateDetails />} />         
          <Route exact path="/add-corporate" element={<CorporateAdd />} />         
          <Route exact path="/edit-corporate/:corporateId" element={<CorporateEdit />} />         
          <Route exact path ="/test" element ={<Test/>}/>


          </Routes>
          </Router>
    </div>
  );
}

export default App;
