import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactPreviewLCalendar from "./ReactPreviewLCalendar";
import ReactPreviewPCalendar from "./ReactPreviewPCalendar";
import { useParams } from "react-router-dom";
import { useAuth } from "../AuthContext";
import axios from "axios";
import { generateYearMonths } from "./generateYearMonths";
import { Link } from "react-router-dom";
import moment from "moment";

const AdminPrintCalendar = () => {
  const containerRefs = useRef([]);
  const [calendarData, setCalendarData] = useState(null);
  const [googleCalendarEvents, setGoogleCalendarEvents] = useState([]);
  const { calendarId } = useParams();
  const { filepath, apipath } = useAuth();
  const [shippingId, setShippingId] = useState("");
  const [ordersId, setOrdersId] = useState("");

  const [shippingData, setShippingData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    alternatePhone: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
    paymentMethod: "UPI",
    additionalInformation: "",
    calendar_id: calendarId || "",
  });

  const [orderData, setOrderData] = useState({
    qty: 1,
    orderStatus: "Pending",
    paymentStatus: "Pending",
    orderAmount: 0,
    shippingPrice: 0,
    totalPrice: 295,
    orderId: "",
    orderConfirmed: false,
    dispatched: false,
    OntheWay: false,
    pickup: false,
    calendar_id: calendarId || "",
    flat: "",
    houseNo: "",
    building: "",
    company: "",
    apartment: "",
    area: "",
    street: "",
    sector: "",
    landmark: "",
    country: "",
  });

  const [isLoading, setIsLoading] = useState(false); // Loader state

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        const response = await axios.get(
          `${apipath}/calendar/details/${calendarId}`
        );
        setCalendarData(response.data.calendar);
        setShippingId(response.data.calendar.shipping_id);
        setOrdersId(response.data.calendar.order_id);
      } catch (error) {
        console.error("Error fetching calendar data:", error);
      }
    };

    const fetchOrders = async () => {
      try {
        if (ordersId) {
          const response = await axios.get(
            `${apipath}/order/details/${ordersId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setOrderData(response.data.order);
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    const fetchShipping = async () => {
      try {
        if (shippingId) {
          const response = await axios.get(
            `${apipath}/shipping/details/${shippingId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setShippingData(response.data.shippingInformation);
        }
      } catch (error) {
        console.error("Error fetching shipping details:", error);
      }
    };

    fetchOrders();
    fetchCalendarData();
    fetchShipping();
  }, [calendarId, apipath, shippingId, ordersId, token]);

  useEffect(() => {
    if (calendarData) {
      const fetchGoogleCalendarEvents = async () => {
        try {
          const months = generateYearMonths(calendarData.startYearMonth);

          const eventsPromises = months.map((month) => {
            const year = month.year();
            const monthIndex = month.month();
            const timeMin = new Date(year, monthIndex, 1).toISOString();
            const timeMax = new Date(year, monthIndex + 1, 0).toISOString();

            return fetch(
              `https://www.googleapis.com/calendar/v3/calendars/en.indian%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAjfBYZVOqpBtwp6Ty55hL9-eXAVD6rKUs&timeMin=${timeMin}&timeMax=${timeMax}`
            )
              .then((response) => response.json())
              .then((data) =>
                data.items.map((event) => ({
                  start: new Date(event.start.date || event.start.dateTime),
                  end: new Date(event.end.date || event.end.dateTime),
                  title: event.summary,
                  month: monthIndex,
                }))
              );
          });

          const allEvents = await Promise.all(eventsPromises);
          const mergedEvents = allEvents
            .flat()
            .sort((a, b) => new Date(a.start) - new Date(b.start));

          setGoogleCalendarEvents(mergedEvents);
        } catch (error) {
          console.error("Error fetching Google Calendar events:", error);
        }
      };

      fetchGoogleCalendarEvents();
    }
  }, [calendarData]);

  const downloadPDF = async () => {
    setIsLoading(true); // Start loading

    try {
      // Check if there are elements to capture
      if (!containerRefs.current.length) {
        console.error("No elements to capture.");
        return;
      }

      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [5.9, 4.4], // PDF size
      });
      // Temporarily add no-border class to upper-layout-box elements
      // const originalBorders = [];
      // containerRefs.current.forEach((el, index) => {
      //   if (el && el.querySelector('.upper-textbox')) {
      //     const upperTextbox = el.querySelector('.upper-textbox');
      //     originalBorders[index] = upperTextbox.style.border;
      //     upperTextbox.style.border = 'none';
      //   }
      // });
      // const originalBorders = [];
      // containerRefs.current.forEach((el, index) => {
      //   if (el && el.querySelector('.upper-textbox')) {
      //     const upperTextbox = el.querySelector('.upper-textbox');
      //     originalBorders[index] = upperTextbox.style.border;
      //     upperTextbox.style.border = 'none';
      //   }
      // });
      const originalBorders = [];
      const originalFontSizes = [];

      containerRefs.current.forEach((el, index) => {
        if (el && el.querySelector(".upper-textbox")) {
          const upperTextbox = el.querySelector(".upper-textbox");
          // Save original border styles
          originalBorders[index] = upperTextbox.style.border;
          upperTextbox.style.border = "none";

          // Save original font sizes
          originalFontSizes[index] = {
            h5: upperTextbox.querySelector("h5")?.style.fontSize,
            h6: upperTextbox.querySelector("h6")?.style.fontSize,
            p: upperTextbox
              .querySelectorAll("p")
              .forEach((p) => p.style.fontSize),
          };

          // Increase font sizes
          if (upperTextbox.querySelector("h5")) {
            upperTextbox.querySelector("h5").style.fontSize = "27px"; // Increase font size
          }
          if (upperTextbox.querySelector("h6")) {
            upperTextbox.querySelector("h6").style.fontSize = "24px"; // Increase font size
          }
          upperTextbox
            .querySelectorAll("p")
            .forEach((p) => (p.style.fontSize = "20px")); // Increase font size
        }
      });
      for (let i = 0; i < containerRefs.current.length; i++) {
        const element = containerRefs.current[i];

        // Ensure that the element is loaded and visible
        if (!element) {
          console.error("Element not found or not visible.");
          continue;
        }

        try {
          const canvas = await html2canvas(element, {
            scale: 2, // Increase the scale for higher quality
            useCORS: true, // Enable CORS to handle image loading issues
            allowTaint: true, // Allow cross-origin images
            logging: false, // Disable logging for a cleaner output
          });

          const imgData = canvas.toDataURL("image/png");

          // Dimensions of the container in inches
          const containerWidth = 5.9; // PDF width
          const containerHeight = 4.4; // PDF height

          // Image dimensions
          const imgWidth = canvas.width / 96; // Convert px to inches
          const imgHeight = canvas.height / 96; // Convert px to inches

          // Calculate aspect ratios
          const imgAspectRatio = imgWidth / imgHeight;
          const containerAspectRatio = containerWidth / containerHeight;

          let finalImgWidth, finalImgHeight;

          if (imgAspectRatio > containerAspectRatio) {
            // Image is wider relative to the container
            finalImgWidth = containerWidth;
            finalImgHeight = containerWidth / imgAspectRatio;
          } else {
            // Image is taller relative to the container
            finalImgHeight = containerHeight;
            finalImgWidth = containerHeight * imgAspectRatio;
          }

          // Center the image on the page
          const x = (containerWidth - finalImgWidth) / 2;
          const y = (containerHeight - finalImgHeight) / 2;

          // Add image to PDF
          if (i > 0) pdf.addPage(); // Add a new page for each subsequent container
          pdf.addImage(imgData, "PNG", x, y, finalImgWidth, finalImgHeight);
        } catch (error) {
          console.error("Error capturing element:", error);
        }
      }

      // Save the PDF
      // pdf.save("download.pdf");
      pdf.save(`${shippingData.firstName}_${orderData.orderId}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  // Utility function to group items into pairs
  const groupItems = (items) => {
    const grouped = [];
    for (let i = 0; i < items.length; i += 2) {
      grouped.push(items.slice(i, i + 2));
    }
    return grouped;
  };

  const groupItem = (items) => {
    const grouped = [];
    for (let i = 0; i < items.length; i += 1) {
      grouped.push(items.slice(i, i + 1));
    }
    return grouped;
  };

  const months = generateYearMonths(
    calendarData ? calendarData.startYearMonth : new Date()
  );
  console.log(calendarData);
  return (
    <div>
      <div className="container custom-container sticky-top">
        <div>
          <button className="pre-button">
            <Link to="/adminDashboard" className="back-link">
              {" "}
              Back{" "}
            </Link>
          </button>
        </div>
        <div>
          <button onClick={downloadPDF} disabled={isLoading}>
            Download PDF {isLoading && <span className="loader"></span>}{" "}
          </button>
        </div>
      </div>

      {/* Display loader when isLoading is true */}

      <div className="upper-layout-box">
        <div
          className="upper-textbox"
          ref={(el) => (containerRefs.current[0] = el)}
        >
          {/* <div className='user-detail-name'>
      <h6>PERSONALIZED CALENDAR FOR   <span>{shippingData.firstName}</span> </h6>
      </div> */}
          <div className="user-detail-name preview-center">
            <h1>
              PERSONALIZED CALENDAR FOR <span>{shippingData.firstName}</span>{" "}
            </h1>
          </div>
          <div className="company-basic-detail">
            <div>
              <Link to="/" className="link-class preview-footer">
                {" "}
                www.GiftOnly4U.com{" "}
              </Link>
            </div>
            <div className="text-center">
              <p className="cursive">
                simply a{" "}
                <span className="font-strong">
                  <strong>PRESSIdeas</strong>
                </span>{" "}
                initiative
              </p>
              <img
                src={`${process.env.PUBLIC_URL}/imgs-calendar/logo1.png`}
                alt=""
                className="logo-img"
              />
            </div>
            <div>
              <Link to="" className="press-class preview-footer">
                {" "}
                www.pressideas.com{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="upper-layout-box">
        <div
          className="upper-textbox"
          ref={(el) => (containerRefs.current[1] = el)}
        >
          <div className="shipping-pdf-info">
            <h5 className="courier">By Courier </h5>
            <h6>
              To,
              <br />
              {shippingData.firstName} {shippingData.lastName}
              <br />
              {shippingData.houseNo} {shippingData.address}
              <br />
              {shippingData.landmark}
              <br />
              {shippingData.city} {shippingData.state} <br />
              {shippingData.additionalInformation}
            </h6>
            <div className="address-num">
              <p>Pincode :{shippingData.pinCode}</p>
              <p>+91-{shippingData.phone}</p>
            </div>
          </div>

          <div className="undelivered-box">
            <h6>If undelivered, return to:</h6>
            <p>
              <b>PRESSIdeas</b>, Mr.Mohinder Gupta/Nitin Building Number 57,
              G-8, Manjusha Building, Nehru Place, Delhi - 110019
            </p>
          </div>
        </div>
      </div>

      {calendarData &&
        months.map((month, index) => {
          const monthName = month.format("MMMM").toLowerCase();
          const monthPicKey = `${monthName}Pic`;
          const viewTypeKey = `${monthName}ViewType`;
          const isPortrait = calendarData[viewTypeKey] === "Horizontal";
          const preview = `${monthName}Preview`;
          const isPreview = calendarData[preview] === "normal";

          const imageSrc =
            calendarData[monthPicKey] && calendarData[monthPicKey] !== "null"
              ? filepath + calendarData[monthPicKey]
              : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`;

          const events = [];
          const eventItems = [];
          for (let i = 1; i <= 4; i++) {
            const eventDateKey = `${monthName}Event${i}Date`;
            const eventCaptionKey = `${monthName}Event${i}Caption`;
            const eventColorKey = `${monthName}Event${i}Color`;
            if (calendarData[eventDateKey]) {
              events.push({
                start: new Date(
                  month.year(),
                  month.month(),
                  calendarData[eventDateKey]
                ),
                end: new Date(
                  month.year(),
                  month.month(),
                  calendarData[eventDateKey]
                ),
                title: calendarData[eventCaptionKey] || "",
              });
              eventItems.push(
                <li key={i} style={{ color: calendarData[eventColorKey] }}>
                  {calendarData[eventDateKey]} {calendarData[eventCaptionKey]}
                </li>
              );
            }
          }

          {
            /* const monthHolidays = googleCalendarEvents.filter(
      (event) => event.month === month.month()
    ); */
          }

          const monthHolidays = googleCalendarEvents
            .filter((event) => moment(event.start).month() === month.month())
            .slice(0, 4);

          const groupedEventItems = groupItems(eventItems);
          const groupedHolidays = groupItem(monthHolidays);

          return (
            <div className="print-pdf" key={index}>
              <div className="pdf-inner-pages">
                <div
                  id={`month-${index}`}
                  className="print-pdf"
                  key={index}
                  ref={(el) => (containerRefs.current[2 + index] = el)}
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {/* <div className="watermark">
              <img src={`${process.env.PUBLIC_URL}/imgs-calendar/transparent-logo.png`} alt="Watermark Logo" />
            </div> */}
                  {isPortrait ? (
                    <div className="horizontal-container">
                      {isPreview ? (
                        <div className="img-box1">
                          <img
                            src={imageSrc}
                            alt={month.format("MMMM")}
                            onError={(e) => {
                              e.target.src = `${process.env.PUBLIC_URL}/imgs-calendar/l.png`;
                            }}
                          />
                        </div>
                      ) : (
                        <div className="img-box2">
                          <img
                            src={imageSrc}
                            alt={month.format("MMMM")}
                            onError={(e) => {
                              e.target.src = `${process.env.PUBLIC_URL}/imgs-calendar/l.png`;
                            }}
                          />
                        </div>
                      )}
                      <div className="calendar-box">
                        <div className="horizontal-calendar">
                          <h1>{month.format("MMMM YYYY")}</h1>
                          <ReactPreviewLCalendar
                            year={month.year()}
                            month={month.month()}
                            googleCalendarEvents={googleCalendarEvents.filter(
                              (event) => event.month === month.month()
                            )}
                            calendarData={calendarData}
                          />
                        </div>
                        <div className="horizontal-event">
                          <div className="pdf-event">
                            {groupedEventItems.map((pair, index) => (
                              <p
                                key={index}
                                style={{
                                  marginRight: "5px",
                                  fontSize: "11px",
                                }}
                              >
                                {pair[0]}
                                {pair[1]}
                              </p>
                            ))}
                          </div>
                          <div className="pdf-holiday">
                            {groupedHolidays.map((pair, index) => (
                              <li
                                key={index}
                                style={{
                                  marginRight: "5px",
                                  fontSize: "11px",
                                }}
                              >
                                <span>
                                  {moment(pair[0].start).format("DD")}{" "}
                                  {pair[0].title}
                                </span>
                                {/* {pair[1] && (
                                    <span>
                                      {moment(pair[1].start).format("DD")} -{" "}
                                      {pair[1].title}
                                    </span>
                                  )} */}
                              </li>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="vertical-container">
                      {/* <div className="vertical-img-box">
                  <img
                    src={imageSrc}
                    alt={month.format('MMMM')}
                    onError={(e) => {
                      e.target.src = `${process.env.PUBLIC_URL}/imgs-calendar/p.png`;
                    }}
                  />
                </div> */}

                      {isPreview ? (
                        <div className="vertical-img-box1">
                          <img
                            src={imageSrc}
                            alt={month.format("MMMM")}
                            onError={(e) => {
                              e.target.src = `${process.env.PUBLIC_URL}/imgs-calendar/p.png`;
                            }}
                          />
                        </div>
                      ) : (
                        <div className="vertical-img-box2">
                          <img
                            src={imageSrc}
                            alt={month.format("MMMM")}
                            onError={(e) => {
                              e.target.src = `${process.env.PUBLIC_URL}/imgs-calendar/p.png`;
                            }}
                          />
                        </div>
                      )}

                      <div className="vertical-calendar-box">
                        <h1>{month.format("MMMM YYYY")}</h1>
                        <div className="vertical-calendar">
                          <ReactPreviewPCalendar
                            year={month.year()}
                            month={month.month()}
                            googleCalendarEvents={googleCalendarEvents.filter(
                              (event) => event.month === month.month()
                            )}
                            view="vertical"
                            calendarData={calendarData}
                          />
                        </div>
                        <div className="vertical-event">
                          <div className="pdf-event">
                            {groupedEventItems.map((pair, index) => (
                              <p
                                key={index}
                                style={{
                                  marginRight: "5px",
                                  fontSize: "11px",
                                }}
                              >
                                {pair[0]}
                                {pair[1]}
                              </p>
                            ))}
                          </div>
                          <div className="pdf-holiday">
                            {groupedHolidays.map((pair, index) => (
                              <li
                                key={index}
                                style={{
                                  marginRight: "5px",
                                  fontSize: "11px",
                                }}
                              >
                                <span>
                                  {moment(pair[0].start).format("DD")}{" "}
                                  {pair[0].title}
                                </span>
                                {/* {pair[1] && (
                                    <span>
                                      {moment(pair[1].start).format("DD")} - {" "}
                                      {pair[1].title}
                                    </span>
                                  )} */}
                              </li>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}

        <div className="bottom-layout-box">
        <div className="inner-layout">
          <div
            className="layout-imgbox"
            ref={(el) => (containerRefs.current[14] = el)}
          >
            <h2
              style={{
                padding: "10px",
                color: "rgb(241, 36, 94)",
                textDecoration: "underline",
                fontWeight: "bold",
                fontSize: "30px",
              }}
            >
              Notes
            </h2>
            <img
              src={`${process.env.PUBLIC_URL}/imgs-calendar/Finalgrid.jpg`}
              alt=""
              // height={"10%"}
            />
          </div>
          <div
            className="layout-imgbox"
            ref={(el) => (containerRefs.current[15] = el)}
          >
            <h2
              style={{
                padding: "10px",
                color: "rgb(241, 36, 94)",
                textDecoration: "underline",
                fontWeight: "bold",
                fontSize: "30px",
              }}
            >
              Notes
            </h2>
            <img
              src={`${process.env.PUBLIC_URL}/imgs-calendar/Finalgrid.jpg`}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPrintCalendar;
