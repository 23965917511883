import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../AuthContext";

const Reset = () => {
  const [ showPassword, setShowPassword ] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || ''; // Retrieve email passed from the previous component
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const { apipath } = useAuth();

  const handleVisibility = (index) => {
    setShowPassword(showPassword === index ? null : index);
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      iziToast.error({
        title: 'Error',
        message: 'Passwords do not match',
      });
      return;
    }

    fetch(apipath + '/mail/update-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password: newPassword }),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => {
            throw new Error(err.message || 'Failed to update password');
          });
        }
        return response.json();
      })
      .then(data => {
        iziToast.success({
          title: 'Success',
          message: data.message || 'Password updated successfully',
        });
        navigate("/login"); // Redirect to login page after successful password reset
      })
      .catch(error => {
        console.error('Error:', error);
        iziToast.error({
          title: 'Error',
          message: error.message || 'Failed to update password',
        });
      });
  };

  return (
    <>
      <div className="container-fluid login">
        <div className="row login-row">
          <div className="col-lg-6">
            <div className="img-login">
              <img src="imgs-calendar/password.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6 login-right">
            <h1>Reset Password</h1>
            <p>Please enter new password</p>
            <form onSubmit={handleSubmit}>
            <div className="login-item col-lg-8 mx-auto">
                <label htmlFor="email">Enter Email </label>
                <input type="text"
                  placeholder="Enter Your email"
                  id="email"
                  value={email}
                  readOnly/>
              </div>
              <div className="login-item col-lg-8 mx-auto">
                <label htmlFor="name">New Password</label>
                <div className="input-group login-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter your new password"
                    aria-label="password"
                    aria-describedby="basic-addon2"
                    id="newPassword"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                  />
                  <div className="input-group-append login-append">
                    <span className="input-group-text" id="basic-addon2">
                      <i className={showPassword ? "bi bi-eye-slash" : "bi bi-eye"} onClick={() => handleVisibility(1)}></i>
                    </span>
                  </div>
                </div>
                
              </div>
              <div className="login-item col-lg-8 mx-auto">
                <label htmlFor="name">Confirm Password</label>
                <div className="input-group login-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    aria-label="password"
                    aria-describedby="basic-addon2"
                    placeholder="Enter your confirm password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  <div className="input-group-append login-append">
                    <span className="input-group-text" id="basic-addon2">
                      <i className={showPassword ? "bi bi-eye-slash" : "bi bi-eye"} onClick={() => handleVisibility(2)}></i>
                    </span>
                  </div>
                </div>
                
              </div>
              <div className="login-button col-lg-8 mx-auto">
                  <button>Continue</button>
              </div>
              <p className="account col-lg-8 mx-auto">
                Remembered Your Passoword ?{" "}
                <Link to="/login" className="text-decoration-none">
                  <span>Login</span>
                </Link>
              </p>
              {/* <p className="pt-3 col-lg-8 mx-auto">or Login Using</p>
              <div className="d-flex justify-content-center">
                <Link to="/">
                  <img src="imgs-calendar/fb.png" alt="" className="mx-3" />
                </Link>
                <Link to="/">
                  <img src="imgs-calendar/gmail.png" alt="" className="mx-3" />
                </Link>
                <Link to="/otp">
                  <img src="imgs-calendar/text.png" alt="" className="mx-3" />
                </Link>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reset;
