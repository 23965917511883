import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import AdminSidebar from './AdminSidebar';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import $ from 'jquery';

const CorporateDetails = () => {
    const navigate = useNavigate();
    const [corporates, setCorporates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { apipath } = useAuth();
    const dataTableRef = useRef();
    
    useEffect(() => {
      fetchCorporates();
      
    }, []);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
      fetchCorporates();
    if (!loading && corporates.length > 0) {
      $(dataTableRef.current).DataTable();
  }
  }, [loading, corporates]);
  
    const fetchCorporates = async () => {
      try {
        const response = await axios.get(apipath + '/corporate/details'); 
        setCorporates(response.data.corporate); 
        setLoading(false);
      } catch (error) {
        console.error('Error fetching corporate:', error);
        setLoading(false);
      }
    };
    
  
    const handleDelete = async (id) => {
      try {
        setIsLoading(true);
        await axios.delete(apipath + `/corporate/details/${id}`);
        await fetchCorporates(); // Refresh corporate after deletion
        setIsLoading(false);
      } catch (error) {
        console.error('Error deleting corporate:', error);
        setIsLoading(false);
      }
    };

    
    const handleUpdate = async (id) => {
      try {
          navigate(`/edit-corporate/${id}`);
      } catch (error) {
          console.log(error);
      }
  };

  return (
    <>
        <AdminSidebar />
        <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Corporate Management</h1>
          </div>
          <div><Link to="/add-corporate"> <button className="btn btn-primary"> Add Corporate
          </button></Link></div>
          <div className="container-fluid">
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column mx-auto">
                <div className="container mt-4">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr className="tr1">
                      <th>S.No.</th>
                        <th>Name</th>
                        <th>Number</th>
                        <th>Email</th>
                        <th>CouponCodes</th>
                        <th>Coupon Qty</th>
                        <th>Total Coupon Qty</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {corporates.map((corporate, index) => (
                      <tr key={corporate._id}>
                        <td className='text-center'>{index + 1}</td>
                        <td>{corporate.name}</td>
                        <td>{corporate.phone}</td>
                        <td>{corporate.email}</td>
                        <td>{corporate.couponCodes}</td>
                        <td>{corporate.couponQuantity}</td>
                        <td>{corporate.totalCouponQuantity}</td>
                        <td>
                          <i className="fa-regular fa-pen-to-square" onClick={() => handleUpdate(corporate._id)}></i> {" "}                          
                          <i 
                              className="fa-solid fa-trash"
                              onClick={() => handleDelete(corporate._id)}
                              style={{ cursor: 'pointer' }}
                            ></i>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default CorporateDetails;