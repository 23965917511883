import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import AdminSidebar from './AdminSidebar';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import $ from 'jquery';
import { Modal } from "react-bootstrap";

const PrintManagementDetails = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { apipath } = useAuth();
    const dataTableRef = useRef();
    const [show, setShow] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    
    const handleShow = (id) => {
      setUserIdToDelete(id);
      setShow(true);
    };
    const handleClose = () => setShow(false);

    useEffect(() => {
      fetchUsers();
    }, []);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
      fetchUsers();
    if (!loading && users.length > 0) {
      $(dataTableRef.current).DataTable();
  }
  }, [loading, users]);
  
    const fetchUsers = async () => {
      try {
        const response = await axios.get(apipath + '/users/details');
        const filteredUsers = response.data.user.filter(user => user.role == "subadmin");  
        setUsers(filteredUsers); 
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setLoading(false);
      }
    };
    
  
    const handleDelete = async () => {
      try {
        setIsLoading(true);
        await axios.delete(apipath + `/users/details/${userIdToDelete}`);
        await fetchUsers(); // Refresh courses after deletion
        setShow(false);
        setIsLoading(false);
      } catch (error) {
        console.error('Error deleting course:', error);
        setIsLoading(false);
      }
    };

    
    const handleUpdate = async (id) => {
      try {
          navigate(`/edit-print-admin/${id}`);
      } catch (error) {
          console.log(error);
      }
  };

  return (
    <>
        <AdminSidebar />
        <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Sub Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this sub admin?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-delete-btn" onClick={handleDelete}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Customer Management</h1>
          </div>
          <div><Link to="/add-print-admin"> <button className="btn btn-primary"> Add Sub Admin
          </button></Link></div>
          <div className="container-fluid">
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column mx-auto">
                <div className="container mt-4">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr className="tr1">
                      <th>Customer S.No.</th>
                        <th>Customer Name</th>
                        <th>Mobile Number</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {users.map((user, index) => (
                      <tr key={user._id}>
                        <td className='text-center'>{index + 1}</td>
                        <td>{user.name}</td>
                        <td>{user.phone}</td>
                        <td>{user.email}</td>
                        <td className={user.status ? "success" : "pending"}>
                            {user.status ? (
                              <span>
                                <i className="fa-solid fa-check"></i> Active
                              </span>
                            ) : (
                              <span>
                                <i className="fa-solid fa-spinner"></i> Inactive
                              </span>
                            )}
                          </td>
                        <td>
                          <i className="fa-regular fa-pen-to-square" onClick={() => handleUpdate(user._id)}></i> {" "}                          
                          <i 
                              className="fa-solid fa-trash"
                              // onClick={() => handleDelete(user._id)}
                              onClick={() => handleShow(user._id)}
                              style={{ cursor: 'pointer' }}
                            ></i>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default PrintManagementDetails;