import React, { useState } from 'react';
import AdminSidebar from './AdminSidebar';
import iziToast from "izitoast";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import validator from "validator";

const CorporateAdd = () => {
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { apipath } = useAuth();

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        city: '',
        state: '',
        country: '',
        address: '',
        pinCode: '',
        logoImage: '',
        couponCodes: '',
        couponQuantity: 0,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));

        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        const validationErrors = {};

        if (!formData.name) {
            validationErrors.name = 'First Name is required';
        }
        if (!formData.email) {
            validationErrors.email = 'Email is required';
        } else if (!validator.isEmail(formData.email)) {
            validationErrors.email = 'Invalid email address';
        }
        if (!formData.phone) {
            validationErrors.phone = 'Phone number is required';
        }
        if (!formData.city) {
            validationErrors.city = 'City is required';
        }
        if (!formData.state) {
            validationErrors.state = 'State is required';
        } 
        if (!formData.country) {
            validationErrors.country = 'Country is required';
        }
        if (!formData.address) {
            validationErrors.address = 'address is required';
        }
        if (!formData.couponCodes) {
            validationErrors.couponCodes = 'Coupon Codes is required';
        }
        if (!formData.couponQuantity) {
            validationErrors.couponQuantity = 'Coupon Quantity is required';
        }
        if (!formData.pinCode) {
            validationErrors.pinCode = 'Pin Code is required';
        }

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        try {
            const response = await fetch(apipath + '/corporate/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (response.status === 201) {
                iziToast.success({
                    title: 'Success',
                    message: data.message || 'Corporate add successful',
                    position: 'topCenter',
                    timeout: 2000,
                });
                navigate('/corporate');
            } else if (response.status === 400) {
                iziToast.error({
                    title: 'Error',
                    message: data.message || 'Email or Mobile No. already exists. Please try another email or mobile no.',
                    position: 'topCenter',
                });
            } else {
                iziToast.error({
                    title: 'Error',
                    message: 'Corporate add failed. Please try again later.',
                    position: 'topCenter',
                });
            }
        } catch (error) {
            console.error("Corporate add error:", error); // Debugging output
            iziToast.error({
                title: 'Error',
                message: 'Corporate add failed. Please try again later.',
                position: 'topCenter',
            });
        }
    };

    return (
        <>
            <AdminSidebar />
            <div className="home">
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Add Corporate</h1>
                    </div>
                    <div className="container-fluid mt-3">
                        <form className="row login-form" onSubmit={handleRegister}>
                            {/* Existing fields */}
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="name" className='mb-2'>Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    // required
                                />
                                {errors.name && <div className="text-danger">{errors.name}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="phone" className='mb-2'>Phone Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter phone number"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    // required
                                />
                                {errors.phone && <div className="text-danger">{errors.phone}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="email" className='mb-2'>Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    // required
                                />
                                {errors.email && <div className="text-danger">{errors.email}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="city" className='mb-2'>City</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter city"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    // required
                                />
                                {errors.city && <div className="text-danger">{errors.city}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="state" className='mb-2'>State</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter state"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    // required
                                />
                                {errors.state && <div className="text-danger">{errors.state}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="country" className='mb-2'>Country</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter country"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleChange}
                                    // required
                                />
                                {errors.country && <div className="text-danger">{errors.country}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="address" className='mb-2'>Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter address"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    // required
                                />
                                {errors.address && <div className="text-danger">{errors.address}</div>}
                            </div>
                           
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="pinCode" className='mb-2'>Pin Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter pin code"
                                    name="pinCode"
                                    value={formData.pinCode}
                                    onChange={handleChange}
                                    // required
                                />
                                {errors.pinCode && <div className="text-danger">{errors.pinCode}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="couponCodes" className='mb-2'>Coupon Codes</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter coupon codes"
                                    name="couponCodes"
                                    value={formData.couponCodes}
                                    onChange={handleChange}
                                    // required
                                />
                                {errors.couponCodes && <div className="text-danger">{errors.couponCodes}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="couponQuantity" className='mb-2'>Coupon Quantity</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter coupon quantity"
                                    name="couponQuantity"
                                    value={formData.couponQuantity}
                                    onChange={handleChange}
                                    // required
                                />
                                {errors.couponQuantity && <div className="text-danger">{errors.couponQuantity}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="logoImage" className='mb-2'>Logo Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    name="logoImage"
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="mb-3 col-lg-12">
                                <button type="submit" className="btn btn-primary">Register</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CorporateAdd;
